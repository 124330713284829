.popup {
	position: relative;
   
  padding-top:196px;
	background: url('./images/top.png') no-repeat top left/100% 197px;
}

 
.close {
	width: 60px;
	height: 60px;
	background: url('./images/close.png') no-repeat center/100%;

	position: absolute;
	right: 8px;
	top: -40px;
}

.content {
	 
	background-color: white;
	padding: 8px 40px 30px;
}

.btn {
	width: 670px;
	height: 86px;
	background: #de2222;
	color: white;
	border-radius: 8px;
	text-align: center;
	line-height: 86px;
  margin-top:24px;
}

.swiperdiv{
  width:100%; 
}

.open2{
  font-weight: 600;
}