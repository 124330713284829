.wai {
	position: relative;
}

.swiperClass {
	 
}

.carousel__item__pic {
	position: relative;
	height: 180px;

	border-radius: 16px;
	padding: 24px;
	background: url('./images/bg.png') no-repeat right top/auto 100%,
		linear-gradient(180deg, #ffebeb 0%, #fff7f7 100%);

	display: flex;
}
.imagediv {
	width: 198px;
	height: 132px;
	position: relative;
  background: white;
  border-radius: 8px;
}
.contentdiv {
	flex-grow: 1;
  margin-left:24px;
  padding-top:18px;
}
.topdiv {
	height: 36px;
	line-height: 36px;
	color: #1a1b22;
}
.topdiv1 {
	display: flex;
}
.icon1 {
	height: 36px;
	width: 90px;
	background: url('./images/pengzhang.png') no-repeat center/ 100%;
}
.miaosha {
  height: 36px;
	width: 108px;
	background: url('./images/miaosha.png') no-repeat center/ 100%;
}
.top2div {
	margin-top: 26px;
}
.top2div2 {
	display: flex;
	align-items: flex-end;
	height: 34px;
	color: #de2222;
}
.icon2 {
  width:91px;
  height:34px;
	background: url('./images/youhui.png') no-repeat center/ 100%;
}
.icon3 {
	height: 34px;
	width: 42px;
	background: url('./images/zuigao.png') no-repeat top center / 100% 22px;
  margin-left:2px;
}
.font1 {
	line-height: 34px;
	font-family: PingFang SC;
	font-weight: 600;

	font-size: 36px;
}
.font2 {
	line-height: 24px;
	font-family: PingFang SC;
	font-weight: 600;
	font-size: 24px;
}
.font3 {
	font-family: PingFang SC;
	color: #1a1b22;
	font-weight: 600;
	font-size: 28px;
}
.left4 {
	margin-left: 4px;
}
.left2 {
	margin-left: 2px;
}
.left8 {
	margin-left: 8px;
}
.bottom2{
  margin-bottom:2px;
}
.navigation {
	height: 8px;
	font-size: 0;
	display: flex;
	align-items: center;
	justify-content: center;

	z-index: 1;
  margin-top:12px;
  
}

.swiperPaginationBullet + .swiperPaginationBullet {
	margin-left: 9px;
}
.swiperPaginationBullet {
	display: inline-block;
	width: 8px;

	height: 8px;

	background: #d9d9dd;
	opacity: 1;
	border-radius: 4px;
}
.swiperactive {
	background-color: #de2222;
}
